<template>
  <section class="block container-padding">
    <section class="block  margin-bottom-new">
      <div class="wrap__editions">
        <div class="block-title posts-block-title">
          <h3 class="title-h3-margin">Выпуски</h3>
          <div
            class="slider-edition__more"
            v-if="$route.name !== 'allposts'"
          >
            <router-link class="" to="/allposts">Смотреть все</router-link>
            <!--            <a href="/allposts">Смотреть все</a>-->
          </div>
        </div>
        <div :class="{ 'show-all-cats': isActive }" class="editions__sorting">
          <div class="editions__sorting-title">Сортировка:</div>
          <ul>
            <li @click="allPostsType()">
              <a :class="{'editions__sorting-title--active': activeEl===0}" @click="chooseType(cat), activeEl = 0">
                <span>Все выпуски</span>
              </a>
            </li>
            <li
              v-for="cat in category.data.results"
              :key="cat.id"
            >
              <a class="typeBtn" :class="{'editions__sorting-title--active': activeEl===cat.id}"  @click="chooseType(cat), activeEl = cat.id">
                <span>{{ cat.name }}</span>
              </a>
            </li>
          </ul>
          <a v-on:click="showAllCats()" href="javascript:void(0)" class="editions__sorting-button">
            <span>Все фильтры</span><svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.0303 9.96967C17.3232 10.2626 17.3232 10.7374 17.0303 11.0303L13.0303 15.0303C12.7374 15.3232 12.2626 15.3232 11.9697 15.0303L7.96967 11.0303C7.67678 10.7374 7.67678 10.2626 7.96967 9.96967C8.26256 9.67678 8.73744 9.67678 9.03033 9.96967L12.5 13.4393L15.9697 9.96967C16.2626 9.67678 16.7374 9.67678 17.0303 9.96967Z" fill="white"/></svg>
          </a>
        </div>
        <div class="editions__list">
          <div
              v-for="item in postsBlock.slice(0, postsBreakpoint)"
              :key="item.id"
              class="editions__block"
          >
            <div
              class="editions__cover"
              @click="openPost(item.id)"
              :post="item.id"
            >
              <a @click="openPost(item.id)"  class="editions__cover-link">
                <!--<img :src="item.video_preview_piresetPostsc" alt="cover" />-->
                <img :src="pathToImage + item.video_preview_pic" alt="cover" />
              </a>
              <div v-if="item.badge_enabled === true" class="editions__tooltip">
                <a @click="openRSHB()" class="editions__tooltip-link"><span></span></a>
                <div class="editions__tooltip-text">
                  Ферма закончила «Школу Фермеров» и получила грант на развитие
                  <a @click="openRSHB()">Подробнее о «Школе Фермеров»</a>
                </div>
              </div>
            </div>
            <div class="editions__title">
              {{ item.title }}
            </div>
            <div class="editions__info-time">
              Выпуск от {{ item.date.split('-').reverse().join('.') }}
            </div>
          </div>
        </div>
        <div
            v-if="this.postsBlock.length >= 6 && this.postsBlock.length >= this.postsBreakpoint"
            @click="getMorePosts"
            class="editions__more-button"
            href="#">
            Показать еще выпуски
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import NewsItem from './NewsItem.vue';
// import CustomLink from './UI/CustomLink.vdue';

export default {
  name: 'Posts',

  data () {
    return {
      isActive: false,
      activeEl: 0,
      postsBreakpoint: 6,
      selectedType: null,
      pathToImage: 'https://fondsvoe.ru/media/static/images/'
    }
  },

  computed: {
    ...mapState('posts', ['posts']),
    ...mapState('posts', ['tag']),
    ...mapState('category', ['category']),
    ...mapState('posts', ['postType']),
    getCategories() {
      return this.category.data.results;
    },
    postsBlock () {
      return this.posts.results
    },

    // sortingList(el) {
    //   return null;
    // },

  },

  async mounted() {
    this.resetPosts()
    await this.getPosts({ tag: this.tag, page: this.page, pageSize: 2 })
    await this.getPostsTypes()

    // this.loading = false
    // if (this.mainPosts.length === 0) {
    //   await this.getNews({page: 1, pageSize: 6 })
    // }
  },

  methods: {
    ...mapActions('posts', ['getPosts']),
    ...mapActions('category', ['getPostsTypes']),
    ...mapActions('posts', ['sortPostType']),
    ...mapMutations('posts', ['resetPosts']),
    openPost(id) {
      // this.$router.push(`/post/${id}`)
      let routeUrl = '/post/' + id
      window.open(routeUrl, '_blank');
    },

    openRSHB() {
      let routeUrl = 'https://www.rshb.ru/farmerschool/';
      window.open(routeUrl, '_blank');
    },

    // async getMorePosts () {
    //   this.page++
    //   await this.getPosts({ page: this.page, pageSize: 2 })
    // },
    async showAllCats () {
      if (this.isActive === true) {
        this.isActive = false
      } else {
        this.isActive = true
      }

    },
    async getMorePosts () {
      this.page++
      this.postsBreakpoint += 6
      // return this.postsBreakpoint
    },
    async allPostsType () {
      this.resetPosts();
      await this.getPosts({ tag: this.tag, page: this.page, pageSize: 2 })
      await this.getPostsTypes()
    },
    async chooseType(el) {
      this.postsBreakpoint = 6;
      this.resetPosts()
      this.loading = true
      this.page = 1
      this.selectedType = el.id
      await this.sortPostType({tag: this.tag, page: this.page, pageSize: 100, elemID: this.selectedType}, {})
      // await this.getPosts({page: this.page, pageSize: 8, typeId: this.activeTab})
      // console.log(this.selectedType);
      // console.log(gg);
      this.loading = false
    }
  },

  components: { Swiper, SwiperSlide},

};
</script>
<style>
.show-all-cats ul li {
  display: block !important;
}
@media (max-width: 575px) {

}
</style>

